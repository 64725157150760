<template>
  <div class="headline">
    <div class="title">{{ title }}</div>
    <div class="subtitle" v-if="subtitle">
      <span>{{ subtitle }}</span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: { type: String, require: true },
    subtitle: { type: String, require: true }
  }
};
</script>
<style scoped lang="scss">
.headline {
  position: relative;
  .title {
    font-size: 50px;
    line-height: 70px;
    color: #333;
    margin-bottom: 10px;
  }
  .subtitle span {
    position: relative;
    width: 100%;
    margin: 0 auto;
    font-size: 32px;
    line-height: 35px;
    color: #a5a5a5;
  }

  .subtitle span:after {
    content: "";
    display: block;
    width: 100px;
    height: 2px;
    background-color: #a5a5a5;
    position: absolute;
    top: 50%;
    left: -130px;
  }
  .subtitle span:before {
    content: "";
    display: block;
    width: 100px;
    height: 2px;
    background-color: #a5a5a5;
    position: absolute;
    top: 50%;
    right: -130px;
  }
}
</style>
