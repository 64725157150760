<template>
  <div class="contact-form">
    <Headline class="mt-8" title="联系我们" subtitle="Contact Us" />
    <div class="info-wrapper wrap-v1">
      <div class="item">
        <div class="icon"><img src="../assets/images/30.png" alt="" /></div>
        <div class="text ml-2">
          <div>售前热线：18026933971</div>
          <div>售后热线：18026933971</div>
        </div>
      </div>
      <div class="item">
        <div class="icon"><img src="../assets/images/31.png" alt="" /></div>
        <div class="text ml-2">
          <div>bainfo@mail.blueasiainfo.com</div>
        </div>
      </div>
      <div class="item">
        <div class="icon"><img src="../assets/images/30.png" alt="" /></div>
        <div class="text ml-2">
          <div>公司地址：</div>
          <div>广东省深圳市宝安区3区龙井2路3号中粮大厦905</div>
        </div>
      </div>
    </div>
    <div class="form-title">您可以填写这个表单，我们的客服人员将稍后联系您</div>

    <el-form
      v-loading="formLoading"
      :model="form"
      class="wrap-v1"
      :rules="rules"
      ref="form"
    >
      <div class="inline">
        <el-form-item prop="name">
          <el-input v-model="form.name" placeholder="如何称呼您"></el-input>
        </el-form-item>
        <el-form-item prop="phone">
          <el-input v-model="form.phone" placeholder="您的联系方式"></el-input>
        </el-form-item>
        <el-form-item prop="questionType">
          <el-select
            v-model="form.questionType"
            placeholder="请选择您需要的帮助"
          >
            <el-option label="商业合作" value="0"></el-option>
            <el-option label="技术支持" value="1"></el-option>
            <el-option label="产品咨询" value="2"></el-option>
            <el-option label="加入蓝亚" value="3"></el-option>
            <el-option label="其他" value="4"></el-option>
          </el-select>
        </el-form-item>
      </div>
      <el-form-item prop="description">
        <el-input
          type="textarea"
          v-model="form.description"
          placeholder="您的需求"
          :autosize="{ minRows: 6 }"
          maxlength="255"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item class="button-wrapper">
        <el-button @click="onSubmit('form')">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { addConsultInfo } from "../service/allapi";
import { Message } from "element-ui";
import Headline from "@/components/headline.vue";
export default {
  components: { Headline },
  data() {
    return {
      form: { name: "", phone: "", questionType: "", description: "" },
      rules: {
        name: {
          required: true,
          min: 2,
          message: "长度最小 2 个字符哦",
          trigger: "blur"
        },
        phone: {
          required: true,
          message: "请输入手机号，长度为11位",
          min: 11,
          max: 11,
          trigger: "blur"
        },
        questionType: [
          { required: true, message: "请选择您需要的帮助", trigger: "blur" }
        ],
        description: {
          required: true,
          max: 255,
          message: "最多 255 个字符",
          trigger: "blur"
        }
      },
      formLoading: false
    };
  },
  methods: {
    onSubmit(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.formLoading = true;
          addConsultInfo(this.form)
            .then(res => {
              if (res.code === 200) {
                Message.success("提交成功，稍后我们将安排工作人员联系您");
              } else {
                Message.error("出现了一点问题，重新试试吧");
              }
              this.formLoading = false;
            })
            .catch(value => {
              Message.error("出现了一点问题，重新试试吧");
              this.formLoading = false;
            });
          // console.log("valid", valid, this.form);
        }
      });
    }
  }
};
</script>
<style scoped lang="scss">
.ml-2 {
  margin-left: 20px;
}
.mt-8 {
  margin-top: 80px;
}
.title {
  margin-top: 80px;
  font-size: 50px;
  color: #333333;
  line-height: 70px;
}
.info-wrapper {
  margin-top: 50px;
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid #d5d5d5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  .item {
    text-align: left;
    display: flex;
    align-items: center;
    line-height: 26px;
    font-size: 16px;
    color: #333333;
    .icon {
      width: 26px;
      img {
        width: 100%;
      }
    }
  }
}
.form-title {
  margin-top: 90px;
  margin-bottom: 50px;
  font-size: 40px;
  color: #333333;
  line-height: 56px;
}
.inline {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .el-form-item {
    width: 31%;
    .el-select {
      width: 100%;
    }
  }
}
.button-wrapper {
  text-align: left;
  .el-button {
    background-color: #007bb9;
    width: 400px;
    font-size: 20px;
    color: #ffffff;
    line-height: 24px;
  }
}
/deep/.el-textarea__inner {
  padding: 25px 40px;
}
/deep/.el-input input {
  height: 60px;
  font-size: 16px;
}
/deep/.el-textarea {
  font-size: 16px;
}
</style>
