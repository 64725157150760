import { render, staticRenderFns } from "./bigimg.vue?vue&type=template&id=7f48d539&scoped=true&"
import script from "./bigimg.vue?vue&type=script&lang=js&"
export * from "./bigimg.vue?vue&type=script&lang=js&"
import style0 from "./bigimg.vue?vue&type=style&index=0&id=7f48d539&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f48d539",
  null
  
)

export default component.exports