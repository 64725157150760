<template>
  <div class="bg-img">
    <div class="content">
      <Headline class="title" :title="title" />
      <div class="sub-title">
        {{ subTitle }}
      </div>
    </div>
    <img :src="imgUrl" alt="" />
  </div>
</template>
<script>
import Headline from "./headline";
export default {
  components: { Headline },
  props: {
    imgUrl: { type: String, required: true },
    title: { type: String, required: true },
    subTitle: { type: String },
    eng: { type: String }
  }
};
</script>
<style scoped lang="scss">
@media screen and (max-width: 960px) {
  .bg-img {
    /deep/.title {
      font-size: 45px !important;
    }
  }
  .sub-title {
    font-size: 20px !important;
  }
}
.bg-img {
  position: relative;
  user-select: none;
  .content {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /deep/.title {
      width: 100%;
      font-size: 65px;
      font-weight: 600;
      color: #ffffff;
      line-height: 59px;
    }
    /deep/.subtitle span {
      color: #ffffff;
    }
    .sub-title {
      width: 100%;
      margin: 30px auto 0;
      font-size: 30px;
      font-weight: 300;
      color: #ffffff;
      line-height: 59px;
    }
  }
}
</style>
